<template>
  <div class="order-page-product-info">
    <div v-for="item in orderItemList" :key="item.id" class="product-wrapper">
      <!-- 未关联产品 -->
      <div class="wrapper haveNotProduct" v-if="!item.productId">
        <span>SKU: {{ item['productExternalSkuCode'] }}</span>
        <span class="mlr10">x</span>
        <el-button type="info" size="mini" circle>{{ item.productCount }}</el-button>
      </div>
      <div class="wrapper" v-else>
        <div class="image">
          <base-popover placement="right" trigger="hover" :visible-arrow="false">
            <div slot="reference">
              <!-- is-delete-product 排单中之前才显示产品删除，排单中产品信息不可改 -->
              <div v-if="item.productIsDeleted && orderData.orderStatus < 41" class="deleted">产品已删除</div>
              <div class="product-line" v-if="item.productIsDeleted && orderData.orderStatus < 41"></div>
              <div :class="[item.productIsDeleted && orderData.orderStatus < 41 ? 'mask' : '']">
                <base-image :src="item.productShowCover" size="100" imgSize="100" :z-index="9999" />
              </div>
            </div>
            <div
              v-if="item.productCnName"
              style="height: 40px; padding-left: 20px; line-height: 40px; background: #fff; border: 1px solid #e8e8e8"
            >
              {{ item.productCnName }}
            </div>
            <div
              style="height: 40px; padding-left: 20px; line-height: 40px; background: #fff; border: 1px solid #e8e8e8"
              v-else
            >
              暂无
            </div>
            <base-image
              class="original-im"
              :src="item.productShowCover"
              size="450"
              imgSize="450"
              :z-index="9999"
            />
          </base-popover>
        </div>
        <div class="info">
          <div>款式：{{ item.productStructName || '暂无' }}</div>
          <div>尺码：{{ item.productSizeName || '暂无' }}</div>
          <div>SKU：{{ item.productExternalSkuCode || '暂无' }}</div>
          <div>FNSKU：{{ getFnsku(item.id) }}</div>
        </div>
      </div>
    </div>
    <el-divider v-if="surplusNum > 0" class="nowrap cursor-pointer" @click="handleOpen"
      >{{ opened ? '收起' : '展开剩余' }}{{ surplusNum }}项产品信息</el-divider
    >
  </div>
</template>

<script>
import hoverCopy from '@/views/components/hoverCopy'
import * as orderStatus from '@/utils/constant'
import BasePopover from '@/views/components/basePopover'
export default {
  components: {
    BasePopover,
    hoverCopy
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    doLayout: Function,
    sup_this: {},
    skuList: {},
    orderData: {},
    // 是否有关联权限
    isRelation: {
      type: Boolean,
      default: false
    },
    // 是否展开所有产品
    isShowAllProduct: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orderStatus,
      opened: false
    }
  },
  created() {
    console.log(this.data)
  },
  computed: {
    showDelete() {
      return [11, 40, 20].some((orderStatus) => this.orderData.orderStatus == orderStatus)
    },
    orderItemList({ isShowAllProduct, data, opened }) {
      if (!Array.isArray(data)) return []
      if (opened) return data
      return isShowAllProduct ? data : data.slice(0, 1)
    },
    surplusNum({ data }) {
      if (!Array.isArray(data)) return 0
      return data.length - 1
    }
  },
  methods: {
    getFnsku(id) {
      let data = this.orderData?.externalOrderFbaItemList
      if (data && data.length) {
        const result = data.filter((item) => item.id === id)[0]
        return $GET(result, 'productLabelBarcode', '暂无')
      }
      return '暂无'
    },

    handleOpen() {
      this.opened = !this.opened
      this.$nextTick(function () {
        if (typeof this.doLayout === 'function') this.doLayout()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.order-page-product-info {
  .product-wrapper {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .wrapper {
      display: flex;
      align-items: center;
      .image {
        flex: none;
        width: 100px;
        height: 100px;
        margin-right: 20px;
        .deleted {
          position: absolute;
          color: red;
          font-size: 14px;
          font-weight: bold;
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
        }
        .product-line {
          position: absolute;
          margin-top: 35px;
          width: 100px;
          height: 30px;
          border: 1px dashed red;
        }

        .mask {
          position: relative;
          background-color: white;
          opacity: 0.5;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .haveNotProduct {
      display: flex;
      align-items: center;
    }
  }
}
</style>
